import { call, put, takeLatest, select } from "redux-saga/effects";
import { ApiKeyActions, ApiKeySelectors } from "./api-key.slice";
import { toast } from "react-toastify";
import ApiKeyRequest from "./api-key.request";

function* ApiKeySaga() {
  yield takeLatest(ApiKeyActions.createApiKey, createApiKey);
  yield takeLatest(ApiKeyActions.updateApiKey, updateApiKey);
  yield takeLatest(ApiKeyActions.getAllApiKey, getAllApiKey);
  yield takeLatest(ApiKeyActions.getApiKeyById, getApiKeyById);
  yield takeLatest(ApiKeyActions.deleteApiKey, deleteApiKey);
  yield takeLatest(ApiKeyActions.removeAllKey, removeAllKey);
}

function* createApiKey({ payload }) {
  try {
    const { onSuccess, input } = payload;
    const response = yield call(ApiKeyRequest.createApiKey, input);
    if (response.success) {
      toast.success("Tạo API KEY thành công");
      onSuccess();
  }
  } catch (error) {
    toast.error(
      error?.response?.data?.message || "Có lỗi xảy ra khi tạo API KEY"
    );
  }
}

function* updateApiKey({ payload }) {
  try {
    const { onSuccess, input, id } = payload;
    const response = yield call(ApiKeyRequest.updateApiKey, {
      input,
      id,
    });
    if (response.success) {
      toast.success("Cập nhật API KEY thành công");
      onSuccess();
    }
  } catch (error) {
    toast.error(
      error?.response?.data?.message || "Có lỗi xảy ra khi cập nhật API KEY"
    );
  }
}

function* getAllApiKey() {
  try {
    const pagination = yield select(ApiKeySelectors.pagination);
    const keySearch = yield select(ApiKeySelectors.keySearch);
    const response = yield call(ApiKeyRequest.getAllApiKey, {
      page: pagination.page,
      pageSize: pagination.pageSize,
      search: keySearch || "",
    });
    if (response.success) {
      yield put(ApiKeyActions.getAllApiKeySuccess(response.data.keys));
      yield put(ApiKeyActions.setPagination(response.data.pagination));
    }
  } catch (error) {
    toast.error("Có lỗi xảy ra khi lấy API KEY");
  }
}

function* getApiKeyById({ payload }) {
  try {
    const { id } = payload;
    const response = yield call(ApiKeyRequest.getApiKeyById, id);
    if (response.success) {
      yield put(ApiKeyActions.getApiKeyByIdSuccess(response.data));
    }
  } catch (error) {
    toast.error("Có lỗi xảy ra khi lấy API KEY");
  }
}

function* deleteApiKey({ payload }) {
  try {
    const { id, onSuccess } = payload;
    const response = yield call(ApiKeyRequest.deleteApiKey, id);

    if (response.success) {
      onSuccess();
    }
  } catch (error) {
    toast.error(
      error?.response?.data?.message || "Có lỗi xảy ra khi xóa API KEY"
    );
  }
}

function* removeAllKey({ payload }) {
  try {
    const { onSuccess } = payload;
    const response = yield call(ApiKeyRequest.removeAllKey);

    if (response.success) {
      toast.success(response?.message || "API KEY đã được xóa thành công !")
      onSuccess();
    }
  } catch (error) {
    console.log(error);
    toast.error(
      error?.response?.data?.message || "Có lỗi xảy ra khi xóa API KEY"
    );
  }
}

export default ApiKeySaga;
