import React, { useState, useEffect } from "react";
import { Close } from "@mui/icons-material";
import Modal from "../../components/base/modal/Modal";
import MST from "../../components";

const VIP_LEVELS = [0, 1, 2, 3, 4, 5];

// Default VIP level percentages matching the model defaults
const DEFAULT_VIP_PERCENTAGES = {
  0: 100,
  1: 35,
  2: 25,
  3: 15,
  4: 0,
  5: -100
};

const VipLevel = ({ isOpen, onClose, vipPrices, setVipPrices, basePrice }) => {
  const [priceIncreases, setPriceIncreases] = useState({ ...DEFAULT_VIP_PERCENTAGES });
  const [calculatedPrices, setCalculatedPrices] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    if (isOpen) {
      // If vipPrices exist and aren't empty, use them; otherwise use defaults
      const initialPrices = {};

      if (vipPrices && vipPrices.length > 0) {
        // Populate from provided vipPrices
        VIP_LEVELS.forEach(level => {
          const vipPrice = vipPrices.find(vp => vp.level === level);
          initialPrices[level] = vipPrice ? vipPrice.increasePercentage : DEFAULT_VIP_PERCENTAGES[level];
        });
      } else {
        // Use defaults
        Object.assign(initialPrices, DEFAULT_VIP_PERCENTAGES);
      }

      setPriceIncreases(initialPrices);
      updateCalculatedPrices(initialPrices);
    } else {
      setError("");
    }
  }, [isOpen, vipPrices, basePrice]);

  const updateCalculatedPrices = (increases) => {
    if (!basePrice) return;

    const newCalculatedPrices = VIP_LEVELS.reduce((acc, level) => {
      const increase = parseFloat(increases[level]) || 0;
      acc[level] = basePrice * (1 + increase / 100);
      return acc;
    }, {});
    setCalculatedPrices(newCalculatedPrices);
  };

  const validatePriceIncrease = (increase, level) => {
    if (increase === "") return true;
    setError("");
    return true;
  };

  const handlePriceIncreaseChange = (e, level) => {
    const newValue = e.target.value;
    if (validatePriceIncrease(newValue, level)) {
      const newPriceIncreases = { ...priceIncreases, [level]: newValue };
      setPriceIncreases(newPriceIncreases);
      updateCalculatedPrices(newPriceIncreases);
    }
  };

  const handleSave = () => {
    const updatedVipPrices = VIP_LEVELS.map((level) => ({
      level,
      increasePercentage: parseFloat(priceIncreases[level]) || 0,
    }));
    setVipPrices(updatedVipPrices);
    onClose();
  };

  const handleReset = () => {
    setPriceIncreases({ ...DEFAULT_VIP_PERCENTAGES });
    updateCalculatedPrices(DEFAULT_VIP_PERCENTAGES);
  };

  const renderContent = (
    <div className="bg-white p-4 w-[480px] max-w-full">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-bold text-gray-800">
          Cấu hình giá khách hàng VIP
        </h2>
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 transition-colors"
        >
          <Close className="h-6 w-6" />
        </button>
      </div>

      <div className="bg-orange-100 rounded-lg p-4 flex justify-center items-center ">
        <p className="text-orange-800 font-semibold">
          Giá gốc: {basePrice?.toLocaleString() || 0} VND
        </p>
      </div>
      <button
        onClick={handleReset}
        className="text-sm text-blue-600 hover:text-blue-800 hover:underline mb-6 flex justify-end w-full" 
      >
        Khôi phục mặc định
      </button>

      <div className="space-y-3 mb-6">
        <div className="grid grid-cols-3 gap-2 px-4 py-2 bg-gray-200 rounded-lg font-semibold text-gray-700">
          <span>Cấp VIP</span>
          <span>% Thay đổi</span>
          <span className="text-right">Giá áp dụng</span>
        </div>

        {VIP_LEVELS.map((level) => (
          <div
            key={level}
            className="grid grid-cols-3 gap-2 items-center bg-gray-50 px-4 py-2 rounded-lg"
          >
            <span className="text-md font-semibold text-gray-700">
              VIP {level}
            </span>
            <div className="relative">
              <MST.Input
                type="number"
                value={priceIncreases[level]}
                onChange={(e) => handlePriceIncreaseChange(e, level)}
                step={0.1}
                className="text-center max-w-[120px]"
              />
              <span className="absolute right-10 top-1/2 transform -translate-y-1/2 text-gray-500 ">
                %
              </span>
            </div>
            <div className="text-right">
              <p className="font-semibold text-green-600">
                {calculatedPrices[level]?.toLocaleString() || 0} VND
              </p>
            </div>
          </div>
        ))}
      </div>

      {error && (
        <p className="mt-4 text-red-500 text-center text-sm">{error}</p>
      )}

      <div className="flex justify-between pt-4 border-t border-gray-200">
        <MST.Button onClick={onClose} type="outlined">
          Huỷ
        </MST.Button>
        <MST.Button onClick={handleSave} disabled={!!error}>
          Lưu thay đổi
        </MST.Button>
      </div>
    </div>
  );

  return <Modal isShow={isOpen} onHide={onClose} content={renderContent} />;
};

export default VipLevel;