import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { CustomerActions } from "../../app/services/customer/customer.slice";
import MST from "../../components";

const vipLevelList = [
  { name: "0", value: 0, des: "KH cá nhân", color: "bg-gradient-to-r from-slate-100 to-slate-200 text-slate-700" },
  { name: "1", value: 1, des: "KH nhà phân phối", color: "bg-gradient-to-r from-sky-200 to-sky-400 text-white" },
  { name: "2", value: 2, des: "KH đại lý", color: "bg-gradient-to-r from-emerald-300 to-emerald-500 text-white" },
  { name: "3", value: 3, des: "KH đặc biệt", color: "bg-gradient-to-r from-indigo-300 to-indigo-600 text-white" },
  { name: "4", value: 4, des: "KH VIP", color: "bg-gradient-to-r from-amber-400 to-rose-500 text-white" },
  { name: "5", value: 5, des: "KH DIAMOND", color: "bg-gradient-to-r from-rose-400 to-rose-600 text-white" },
];

function UpdateVipLevelModal({ id, customerDetail, onHide }) {
  const dispatch = useDispatch();
  const [vipLevel, setVipLevel] = useState(vipLevelList[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const modalRef = useRef(null);
  const selectedLevelRef = useRef(null);
  
  const currentLevel = vipLevelList[customerDetail?.vipLevel || 0];
  const isUpgrade = vipLevel.value > (customerDetail?.vipLevel || 0);
  const isDowngrade = vipLevel.value < (customerDetail?.vipLevel || 0);
  const hasChanged = vipLevel.value !== (customerDetail?.vipLevel || 0);

  // Arrow animation component
  const LevelChangeArrow = ({ isUpgrade }) => (
    <span className={`
      absolute -top-1 -right-1 w-4 h-4 rounded-full 
      flex items-center justify-center text-xs text-white
      transform transition-all duration-300 ease-in-out
      animate-bounce
      ${isUpgrade ? 'bg-green-500 hover:bg-green-600' : 'bg-orange-500 hover:bg-orange-600'}
    `}>
      <span className="transform transition-transform duration-300 hover:scale-110">
        {isUpgrade ? '↑' : '↓'}
      </span>
    </span>
  );

  useEffect(() => {
    setVipLevel(
      vipLevelList.find(
        (level) => level.value === (customerDetail?.vipLevel || 0)
      ) || vipLevelList[0]
    );
  }, [customerDetail]);

  useEffect(() => {
    // Close balloon menu on click outside
    const handleClickOutside = (event) => {
      if (!event.target.closest('.balloon-menu') && 
          !event.target.closest('.dropdown-trigger')) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const handleSubmit = () => {
    if (hasChanged) {
      setShowConfirm(true);
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    dispatch(
      CustomerActions.update({
        id,
        body: {
          vipLevel: vipLevel.value,
        },
        onSuccess: () => {
          setIsSubmitting(false);
          onHide();
        },
        onError: () => {
          setIsSubmitting(false);
        }
      })
    );
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const selectVipLevel = (level) => {
    if (level.value === vipLevel.value) {
      setIsOpen(false);
      return;
    }
    setVipLevel(level);
    setIsOpen(false);
  };

  // Calculate positions for balloon items
  const renderBalloonMenu = () => {
    if (!isOpen) return null;
    
    // Create a semi-circle arrangement
    return (
      <div className="balloon-menu fixed z-50 pointer-events-none" style={{ width: '100%', height: '100%', top: 0, left: 0 }}>
        <div className="pointer-events-auto absolute" style={{ width: '700px', height: '700px', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
          {vipLevelList.map((level, index) => {
            // Calculate position on a semi-circle
            const totalItems = vipLevelList.length;
            const angleRange = 180; // Semi-circle in degrees
            const angleStep = angleRange / (totalItems - 1);
            const angle = (index * angleStep - 90) * (Math.PI / 180); // Convert to radians and offset to start from top
            const radius = 280; // Radius of the semi-circle
            
            // Calculate position
            const left = `calc(50% + ${Math.cos(angle) * radius}px)`;
            const top = `calc(50% + ${Math.sin(angle) * radius}px)`;
            
            // Define animation delay for popping effect
            const animationDelay = `${index * 0.1}s`;
            
            return (
              <div
                key={level.value}
                className={`
                  absolute transform -translate-x-1/2 -translate-y-1/2
                  ${level.value === vipLevel.value ? 'scale-110 shadow-lg z-10' : 'hover:scale-110 z-0'}
                  transition-all duration-300 cursor-pointer
                  animate-balloon
                `}
                style={{ 
                  left, 
                  top, 
                  animationDelay,
                  opacity: 0, // Start transparent for animation
                  animation: `balloonFadeIn 0.5s ${animationDelay} forwards, balloonFloat 3s ${animationDelay} ease-in-out infinite alternate`
                }}
                onClick={() => selectVipLevel(level)}
              >
                <div className={`
                  ${level.color}
                  rounded-full w-24 h-24 shadow-xl flex flex-col items-center justify-center
                  border-4 border-white
                  transform transition-transform duration-300
                `}>
                  <span className="font-bold text-2xl">{level.name}</span>
                  <span className="text-xs font-medium">{level.des.split(' ')[1]}</span>
                  
                  {level.value !== (customerDetail?.vipLevel || 0) && (
                    <LevelChangeArrow isUpgrade={level.value > (customerDetail?.vipLevel || 0)} />
                  )}
                </div>
                
                {/* String connecting to balloon */}
                <div 
                  className="absolute w-px h-10 bg-gray-300 left-1/2 -top-8"
                  style={{ transform: 'translateX(-50%)' }}
                ></div>
                
                {/* Full name tooltip on hover */}
                <div className={`
                  absolute top-full mt-2 w-32 text-center bg-white p-1 rounded-lg shadow-lg
                  text-sm font-medium opacity-0 group-hover:opacity-100 transition-opacity duration-200
                  ${level.value === vipLevel.value ? 'opacity-100' : 'opacity-0 hover:opacity-100'}
                `}>
                  {level.des}
                  {level.value !== (customerDetail?.vipLevel || 0) && (
                    <span className={`block text-xs font-medium ${level.value > (customerDetail?.vipLevel || 0) ? 'text-green-600' : 'text-orange-600'}`}>
                      {level.value > (customerDetail?.vipLevel || 0) ? 'Nâng cấp' : 'Hạ cấp'}
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div
      className="fixed inset-0 bg-black/60 backdrop-blur-sm flex items-center justify-center z-50"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          !isSubmitting && onHide();
        }
      }}
    >
      <div 
        ref={modalRef}
        className="bg-white rounded-xl p-6 w-[420px] shadow-2xl transform transition-all duration-300 ease-in-out max-h-[90vh] relative"
      >
        {/* Balloon Menu */}
        {renderBalloonMenu()}
        
        {/* Header */}
        <div className="flex justify-between items-start mb-6 pb-3 border-b border-gray-100">
          <div>
            <h2 className="text-xl font-bold text-gray-800 mb-1 flex items-center">
              Thay đổi cấp độ VIP
            </h2>
            <div className="flex items-center">
              <div className="flex items-center text-sm text-gray-500 mt-1">
                <span className="mr-2">Cấp độ hiện tại:</span>
                <span className={`
                  px-2 py-0.5 rounded-full text-sm font-medium relative
                  ${currentLevel.color} 
                  transform transition-all duration-300 ease-in-out
                  hover:scale-105
                `}>
                  Cấp {currentLevel.value} - {currentLevel.des}
                  {hasChanged && <LevelChangeArrow isUpgrade={isUpgrade} />}
                </span>
              </div>
            </div>
          </div>
          <button
            onClick={() => !isSubmitting && onHide()}
            disabled={isSubmitting}
            className="text-gray-400 hover:text-gray-600 transition-colors duration-200 w-8 h-8 flex items-center justify-center hover:bg-gray-100 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
          >
            ✕
          </button>
        </div>

        {/* Selected Level Display */}
        <div className="relative mb-5" ref={selectedLevelRef}>
          <div
            className={`
              rounded-lg cursor-pointer transition-all duration-300 
              ${vipLevel.color}
              hover:shadow-lg hover:scale-[1.02]
              ${isOpen ? 'ring-2 ring-blue-200 ring-opacity-50' : ''}
              ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}
              group dropdown-trigger
            `}
            onClick={() => !isSubmitting && toggleDropdown()}
          >
            <div className="px-4 py-3.5 flex items-center justify-between relative">
              <div className="flex items-center space-x-4">
                <div className={`
                  w-12 h-12 flex items-center justify-center rounded-full 
                  bg-black/10 transition-transform duration-200 
                  group-hover:scale-110 relative
                `}>
                  <span className="font-bold text-lg">{vipLevel.name}</span>
                  {hasChanged && <LevelChangeArrow isUpgrade={isUpgrade} />}
                </div>
                <div>
                  <span className="font-medium text-lg block">
                    {vipLevel.des}
                  </span>
                </div>
              </div>
              <span className={`
                text-lg transition-all duration-300 
                transform group-hover:-translate-y-0.5
                ${isOpen ? 'rotate-180 translate-y-0.5' : ''}
                opacity-75
              `}>
                ▼
              </span>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="flex justify-end space-x-3 pt-3 border-t border-gray-100">
          <MST.Button
            onClick={() => !isSubmitting && onHide()}
            className={`
              px-5 py-2.5
              text-gray-700 rounded-lg transition-all duration-300 
              font-medium min-w-[100px] disabled:cursor-not-allowed
              border border-gray-200
            `}
            disabled={isSubmitting}
          >
            Hủy
          </MST.Button>
          <MST.Button
            onClick={handleSubmit}
            className={`
              px-5 py-2.5
              text-white rounded-lg transition-all duration-300 
              font-medium min-w-[100px] disabled:cursor-not-allowed
              ${isSubmitting ? 'cursor-wait' : ''}
            `}
            disabled={isSubmitting || !hasChanged}
          >
            {isSubmitting ? 'Đang xử lý...' : 'Xác nhận'}
          </MST.Button>
        </div>

        {/* Confirmation Dialog */}
        {showConfirm && (
          <div className="fixed inset-0 bg-black/60 flex items-center justify-center z-[60]">
            <div className="bg-white rounded-lg p-6 w-[380px] shadow-xl">
              <h3 className="text-lg font-bold text-gray-800 mb-2">
                Xác nhận thay đổi
              </h3>
              <p className="text-gray-600 mb-8">
                Bạn có chắc chắn muốn {isUpgrade ? 'nâng cấp' : 'hạ cấp'} khách hàng
                từ <p className={`${isUpgrade ? 'text-green-600' : 'text-orange-600'} text-center`}>
                  <span className="font-medium">Cấp {customerDetail?.vipLevel}</span> {isUpgrade ? 'lên' : 'xuống'}{' '}
                  <span className="font-medium">Cấp {vipLevel.value}</span>?
                </p>
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowConfirm(false)}
                  className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  Hủy
                </button>
                <button
                  onClick={onSubmit}
                  className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  Xác nhận
                </button>
              </div>
            </div>
          </div>
        )}

        {/* CSS animations */}
        <style jsx>{`
          @keyframes balloonFadeIn {
            from { opacity: 0; transform: translate(-50%, -50%) scale(0.5); }
            to { opacity: 1; transform: translate(-50%, -50%) scale(1); }
          }
          
          @keyframes balloonFloat {
            0% { transform: translate(-50%, -50%) translateY(0); }
            100% { transform: translate(-50%, -50%) translateY(-15px); }
          }
        `}</style>
      </div>
    </div>
  );
}

export default UpdateVipLevelModal;