import React from "react";
import MST from "../../components";

const ApiKeyAction = ({
  input,
  errorMessage,
  handleInputChange,
  handleSubmit,
  handleClose,
  isUpdate = false,
  isLoading = false,
}) => {
  return (
    <div className="flex flex-col h-full max-h-[80vh]">
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold">
          {isUpdate ? "Cập nhật API KEY" : "Tạo mới API KEY"}
        </h2>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        <div className="space-y-4">
          <div>
            <div className="mb-2">
              Nhập key
              <span className="text-red-500"> *</span>
            </div>
            <MST.Input
              errorMessage={errorMessage?.key}
              placeholder=" Nhập key"
              value={input.key}
              onChange={(e) => handleInputChange("key", e.target.value)}
            />
          </div>
          <div>
            <div className="mb-2">
              Nhập loại (google_api, rapidapi, gemini_api)
              <span className="text-red-500"> *</span>
            </div>
            <MST.Input
              errorMessage={errorMessage?.type}
              placeholder=" Nhập phân loại"
              value={input.type}
              onChange={(e) => handleInputChange("type", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="p-4 border-t">
        <div className="flex justify-end gap-2">
          <MST.Button onClick={handleSubmit}>
            {isUpdate ? "Cập nhật" : "Thêm"}
          </MST.Button>
          <MST.Button type="outlined" onClick={handleClose}>
            Đóng
          </MST.Button>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyAction;
